@import "../../assets/styles/core";

.sidebarWrapper {
  width: 35vw;
  height: 100svh;
  position: relative;
  border-left: 1px solid;
  border-color: $gray-200;

  /* Mobile devices in portrait orientation */
  @media (max-width: 767px) and (orientation: portrait) {
    width: 100vw;
  }
  /* Mobile devices in landscape orientation */
  @media (max-width: 767px) and (orientation: landscape) {
    width: 100vw;
  }
  /* Tablets in portrait orientation */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: 60vw;
  }
  /* Tablets huge(IPad Pro) in portrait orientation */
  @media screen and (min-width: 1023px) and (max-width: 1368px) and (orientation: portrait) {
    width: 50vw;
  }
  /* Tablets in landscape orientation */
  @media (min-width: 768px) and (max-width: 1080px) and (orientation: landscape) {
    width: 50vw;
  }
  /* HD screens */
  @media (min-width: 1080px) and (max-width: 1368px) {
    width: 45vw;
  }
}

.navbarCustom {
  height: 42px!important;
  min-height: 42px!important;
  border-bottom: 4px solid;
  border-color: $gray-200;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  &_title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

[data-bs-theme="dark"] {
  .navbarCustom {
    background-color: var(--gray-950);
    border-color: var(--gray-800);
  }

  .sidebarWrapper {
    border-color: var(--gray-800);
  }
}
