$blue: #894bff;
$red: #fb2c36;
$green: #00bc7d;

$gray-50: #FAFAFA !default;
$gray-100: #F4F4F5 !default;
$gray-200: #E4E4E7 !default;
$gray-300: #D4D4D8 !default; // dark color text
$gray-400: #9F9FA9 !default;
$gray-500: #71717B !default;
$gray-600: #52525C !default;
$gray-700: #3F3F46 !default;
$gray-800: #27272A !default;
$gray-900: #18181B !default;
$gray-950: #09090B !default;


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import 'bootstrap/scss/mixins';
@import 'bootstrap-icons/font/bootstrap-icons';