@import "core";
@import "~bootstrap/scss/bootstrap";
@import "../fonts/ibmplexsans/ibmplexsans.css";
:root {
  --gray-50: #FAFAFA;
  --gray-100: #F4F4F5;
  --gray-200: #E4E4E7;
  --gray-300: #D4D4D8;
  --gray-400: #9F9FA9;
  --gray-500: #71717B;
  --gray-600: #52525C;
  --gray-700: #3F3F46;
  --gray-800: #27272A;
  --gray-900: #18181B;
  --gray-950: #09090B;


  --red-50: #FEF2F2;
  --red-100: #FFE2E2;
  --red-200: #FFC9C9;
  --red-300: #FFA2A2;
  --red-400: #FF6467;
  --red-500: #FB2C36;
  --red-600: #E7000B;
  --red-700: #C10007;
  --red-800: #9F0712;
  --red-900: #82181A;
  --red-950: #460809;

  --green-50: #ECFDF5;
  --green-100: #D0FAE5;
  --green-200: #A4F4CF;
  --green-300: #5EE9B5;
  --green-400: #00D492;
  --green-500: #00BC7D;
  --green-600: #009966;
  --green-700: #007A55;
  --green-800: #006045;
  --green-900: #004F3B;
  --green-950: #002C22;

  --business-50: #F5F3FF;
  --business-100: #EDE9FE;
  --business-200: #DDD6FF;
  --business-300: #C4B4FF;
  --business-400: #A684FF;
  --business-500: #8E51FF;
  --business-600: #7F22FE;
  --business-700: #7008E7;
  --business-800: #5D0EC0;
  --business-900: #4D179A;
  --business-950: #2F0D68;
}

* {
  font-family: 'IBM Plex Sans' !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
}

html, body, #root {
  margin: 0;
  padding: 0;
}

input::placeholder {
  opacity: 0.45 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

::selection {
  background-color: var(--gray-200) !important;
}

[data-bs-theme="dark"] {
  --bs-body-bg: var(--gray-950) !important;

  ::selection {
    background: var(--gray-600) !important;
  }
}
